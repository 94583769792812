:root {
  --transition: all 0.3s linear;
  --light-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
  --dark-shadow: 0 5px 15px rgba(0, 0, 0, 0.2);
  --max-width: 1170px;
}

.header-text-1,
.header-text-2 {
  margin-bottom: 20px;
  color: #fff;
}
.text-container a {
  text-decoration: none;
}
.expendable-button {
  background-color: #1c3975;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  border-radius: 50%;
  border: none;
  cursor: pointer;
  padding: 0 10px;
  width: 6px;
  height: 20px;
}

.main-title {
  margin: 20px 0 10px 0;
  text-align: left;
  font-weight: 500;
  color: #1c3975;
}

.accordion-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  align-content: flex-start;
  width: 100%;
  font-size: 20px;
  margin-top: 10px;
}

.underline {
  width: 100%;
  height: 0.15rem;
  background: var(--gh-blue-2);
  margin: 1rem auto;
}
.column-container {
  display: flex;
  justify-content: space-between;
  width: 100%;
  cursor: pointer;
}

.column-container:hover {
  background-color: var(--gh-blue-1);
  width: 100%;
}

.text-container {
  text-align: left;
  max-height: 350px;
  opacity: 1;
  animation-name: textanimate;
  animation-duration: 1s;
  margin-top: 3rem;
}
.column-text {
  font-weight: 500;
  color: #1c3975;
}
.accordion__item {
  color: red;
}
a {
  font-family: "Roboto", sans-serif;
  color: #1c3975;
  font-weight: 300;
  font-size: 1.8rem;
  display: block;
}
@keyframes textanimate {
  0% {
    opacity: 0;
    max-height: 15px;
  }
  100% {
    opacity: 1;
    max-height: 350px;
  }
}

@media (max-width: 62rem) {
  .column-text {
    font-size: 1.8rem;
  }
  .text-container a {
    font-size: 1.4rem;
    line-height: 2rem;
    font-weight: 300;
  }
}
