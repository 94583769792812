.section__background {
  color: #fff;
}
.main__section {
  width: 80vw;
  margin: 0 auto;
}
.main__wrapper {
  display: flex;
  width: 80vw;
  max-width: 1320px;
  margin: 5rem auto;
}
.section__right {
  width: 50%;
  display: flex;
  justify-content: center;
}
.section__left {
  width: 50%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-image: radial-gradient(circle at top left, #9b243a 16%, #a72633 100%);
  padding: 0 5%;
}
.section__right img {
}
.section__left h1 {
  padding-bottom: 2rem;
  font-size: 1.7rem;
  font-weight: 600;
  text-transform: uppercase;
  letter-spacing: 2px;
}
.section__left hr {
  border-top: 2px solid #fff;
  border-radius: 5px;
  margin-bottom: 2rem;
}

.section__left p {
  font-size: 1.8rem;
  font-family: "Roboto" sans-serif;
  font-weight: 300;
}
@media (max-width: 120em) {
  .section__right img {
    max-width: 100%;
  }
  .main__wrapper {
    height: auto;
  }
}

@media (max-width: 88em) {
  .section__right img {
    max-width: 100%;
  }
  .main__wrapper {
    height: auto;
  }
}

@media (max-width: 62em) {
  .section__right {
    display: none;
  }
  .section__left {
    width: 100%;
    padding: 5rem 2rem;
  }
  .main__wrapper {
    width: 80vw;
    height: auto;
    margin: 0 auto;
  }
  .section__left p {
    font-size: 1.4rem;
    line-height: 2rem;
  }
  .section__left h1 {
    color: #fff;
    padding-bottom: 2rem;
    font-size: 1.6rem;
    font-weight: 600;
    text-transform: uppercase;
    letter-spacing: 2px;
  }
}
