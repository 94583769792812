.button__wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
}
.manufacturers__button {
  background-color: #1c3975;
  padding: 1.4rem 2.4rem;
  font-family: "Poppins", sans-serif;
  font-size: 1.4rem;
  color: #fff;
  border-radius: 5px;
  text-decoration: none;
  margin: 2rem auto;
}
.first__decoration {
  background-color: #f8f8f8;
  /* background-image: linear-gradient(90deg, #ffffff 50%, rgba(255, 255, 255, 0) 50%),
    url(./assets/images/close-up-hand-holding-tube-scaled.webp);
  background-size: cover; */
  height: 150px;
  background-position: center;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-bottom: 1rem;
}
.inside__divider {
  position: absolute;
  background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTAwJSIgaGVpZ2h0PSI1MHZoIiB2aWV3Qm94PSIwIDAgMTI4MCAxNDAiIHByZXNlcnZlQXNwZWN0UmF0aW89Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PGcgZmlsbD0icmdiYSgyMzgsMjM4LDIzOCwwLjcpIj48cGF0aCBkPSJNNjQwIDE0MEwxMjgwIDBIMHoiLz48L2c+PC9zdmc+);
  background-size: 100% 100%;
  top: 0;
  height: 100%;
  width: 100%;
  transform: scale(1, 1);
}
.first__decoration p,
.first__decoration h1 {
  z-index: 1;
  text-align: center;
}
.first__decoration h1 {
  font-family: "Poppins", sans-serif;
  font-weight: 600;
  font-size: 3.2rem;
  color: #1c3975;
  text-align: center;
}
.first__decoration p {
  font-family: "Roboto", sans-serif;
  font-size: 1.8rem;
  font-weight: 500;
  text-transform: uppercase;
  color: #9f2436;
}

.flex__title {
  justify-content: flex-start !important;
}
.card__subtitle {
  justify-content: flex-start !important;
}
.chrono h2 {
  font-family: "Roboto", sans-serif;
  color: #666;
  font-size: 1.8rem;
  margin-bottom: 2rem;
  font-weight: 300;
}
@media (max-width: 62em) {
  .first__decoration h1 {
    font-family: "Poppins", sans-serif;
    font-weight: 600;
    font-size: 1.8rem;
    color: #1c3975;
    text-align: center;
  }
  .first__decoration p {
    font-family: "Roboto", sans-serif;
    font-size: 1.6rem;
    font-weight: 400;
    text-transform: uppercase;
    color: #9f2436;
    margin-bottom: 1rem;
  }
  .flex__title {
    font-size: 1.8rem !important;
  }
  .chrono h2 {
    font-size: 1.4rem;
    line-height: 2rem;
    font-weight: 300;
  }
}
