.input__label {
  font-size: 1.4rem;
  font-family: "Roboto", sans-serif;
  font-weight: 300;
  color: #737479;
}
.input__label span {
  color: #9f2436;
  font-size: 2.2rem;
}
.inputField__Column {
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 0.8rem 2rem;
}
.inputField__Column input {
  height: 50px;
  padding: 16px;
  background-color: #eee;
  border-width: 0;
  border-radius: 0;
  border-style: solid;
  line-height: 1.7em;
  border: 2px solid #ccc;
}
.inputField__Column textarea {
  background-color: #eee;
  min-height: 150px;
    resize: vertical; /* Allows only vertical resizing */

}
@media (max-width: 88em) {
  .inputField__Column {
    padding: 0.8rem 1rem;
  }
}
