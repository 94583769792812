.image__container {
  width: 300px;

  display: flex;
  justify-content: center;
  align-items: center;
}
.single__image {
  display: block;
  width: 100%;
  height: auto;
}

@media (max-width: 100em) {
  .image__container {
    width: 20%;
  }
}

@media (max-width: 69em) {
  .image__container {
    width: 20%;
  }
  .single__image {
    max-height: 100px;
  }
}
@media (max-width: 62em) {
  .image__container {
    width: 45%;
  }
}
@media (max-width: 42em) {
  .image__container {
    width: 80%;
  }
}
