.pdd__wrapper {
  width: 80vw;
  max-width: 1320px;
  margin: 0 auto;
  color: #5c5c5c;
  padding-top: 20px;
}
.heading__main {
  font-family: "Roboto", sans-serif;
  font-size: 1.8rem;
  font-weight: 500;
  text-transform: uppercase;
  color: #9f2436;
}
.number__marker {
  width: 6px;
  height: 6px;
  background-color: white;
  border-radius: 9999px;
}
.heading__secondary {
  text-align: center;
  font-family: "Poppins", sans-serif;
  font-weight: 600;
  font-size: 3.2rem;
  color: #1c3975;
  margin: 1rem 0;
}
.accordion__explanation {
  text-align: center;
  font-family: "Poppins", sans-serif;
  font-weight: 600;
  font-size: 2.4rem;
  color: #1c3975;
  margin: 1rem 0;
}
.heading__icon {
  font-family: "Poppins", sans-serif;
  font-weight: 600;
  font-size: 3.2rem;
  color: #1c3975;
  margin: 1rem 0;
  text-align: center;
}
.pdd__divider {
  border-top: 3px solid #9f2436;
  border-radius: 10px;
  margin-top: 2rem;
}
.pdd__towho {
  margin: 2rem 0;
  color: #5c5c5c;
}
.pdd__whatfor {
  margin: 2rem 0;
  font-weight: 400;
  color: #1c3975;
}
.unordered__list {
  list-style: none;
  font-family: "Roboto", sans-serif;
  font-weight: 300;
  font-size: 1.8rem;
  margin-bottom: 5rem;
}
.li__wrapper {
  display: flex;
  align-items: center;
  color: #5c5c5c;
}
.unordered__list .list__number {
  width: 4rem;
  height: 4rem;
  background-color: #1c3975;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  margin-right: 1rem;
  margin-bottom: 1rem;
  min-width: 4rem;
  font-size: 5rem;
}
.icons__wrapper {
  display: flex;
  justify-content: space-evenly;
}
.icon__wrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
}
.icon__wrapper svg {
  width: 50px;
  height: 50px;
  color: #1c3975;
}
.icons__text {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 2rem 0;
}
.expl__wrapper {
  display: flex;
  justify-content: space-evenly;
  margin-bottom: 5rem;
}
.expl__text {
  width: 100%;
  text-align: center;
}
.pdd__divider--padding {
  border-top: 3px solid #9f2436;
  border-radius: 10px;
  margin: 2rem 0;
}
.buttons__wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 5rem;
}
.PDD__buttons {
  color: #fff;
  border-radius: 5px;
  background-color: #1c3975;
  width: 130px;
  text-decoration: none;
  font-family: "Roboto", sans-serif;
  font-size: 2rem;
  font-weight: 300;
  padding: 1.2rem 2rem;
  margin-top: 1rem;
}
.PDD__buttons__join {
  color: #fff;
  border-radius: 5px;
  background-color: #1c3975;
  text-decoration: none;
  font-family: "Roboto", sans-serif;
  font-size: 2rem;
  font-weight: 300;
  padding: 1.2rem 2rem;
  margin-top: 1rem;
}
.buttons__width {
  text-align: center;
  /* width: 50vw; */
  margin: 3rem auto 5rem auto;
}
ul {
  list-style-position: inside;
  margin-left: 1rem;
  line-height: 1.5;
}
p {
  line-height: 1.5;
}
@media (max-width: 62em) {
  .icons__wrapper {
    flex-direction: column;
    gap: 5rem;
    margin: 5rem 0;
  }
}
