.section__wrapper {
  width: 80vw;
  max-width: 1320px;
  margin: 0 auto;
  display: flex;
  padding-top: 5rem;
}
.column__left {
  width: 50%;
  display: flex;
  justify-content: centender;
}
.column__right {
  width: 50%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  padding-left: 5rem;
}
.column__right a {
  text-decoration: none;
  margin: 2rem 0;
}
.column__left img {
  max-width: 100%;
}
.column__right hr {
  width: 100%;
  border-top: 2px solid #9f2436;
  border-radius: 10px;
  margin: 2rem 0;
}
.column__right h1 {
  font-family: serif;
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  font-size: 3.2rem;
  color: #1c3975;
}
.right__firstparagraph {
  font-family: "Roboto", sans-serif;
  font-size: 1.8rem;
  font-weight: 500;
  text-transform: uppercase;
  color: #9b243a;
}
.right_lastparagraph {
  font-family: "Roboto", sans-serif;
  font-weight: 300;
  font-size: 1.8rem;
}
.column__right a {
  padding: 1rem 2rem;
  background-color: #1c3975;
  color: #fff;
  width: 210px;
  border-radius: 5px;
  letter-spacing: 0px;
  font-size: 20px;
  font-family: "Roboto", Helvetica, Arial, Lucida, sans-serif !important;
  font-weight: 300 !important;
  background-color: #1c3975;
}
@media (max-width: 62em) {
  .column__left {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-right: 5rem;
  }
  .column__right {
    width: 100%;
    display: flex;
    justify-content: center;
    flex-direction: column;
    padding-left: 0;
  }
  .section__wrapper {
    width: 80vw;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    padding-top: 2rem;
  }
  .column__left {
    padding-right: 0rem;
  }
  .column__left img {
    width: 100%;
    max-width: 600px;
    margin: 0 auto;
    margin-bottom: 2rem;
  }
  .right__firstparagraph {
    font-size: 1.6rem;
  }
  .column__right h1 {
    font-size: 1.8rem;
  }
  .right_lastparagraph {
    font-size: 1.4rem;
    line-height: 2rem;
    font-weight: 300;
  }
  .column__right a {
    font-size: 1.8rem;
  }
}
