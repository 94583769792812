.singleItem__box {
  display: flex;
/*  align-items: flex-start;*/
  justify-content: space-between;
  gap: 2rem;
  background-color: #fff;
  margin: 3rem;
  border-radius: 20px;
  padding: 4rem;
  -webkit-box-shadow: -5px 0px 19px 0px rgba(0, 0, 0, 0.11);
  box-shadow: -5px 0px 19px 0px rgba(0, 0, 0, 0.11);
}

.last__item {
  display: flex;
  justify-content: center;
  align-items: center;
}
.last__item .singleItem__box {
  background-color: #9b243a;
}
.main__wrapper {
  background-image: linear-gradient(180deg, #f2f5f7 0%, rgba(255, 255, 255, 0) 100%) !important;
}
.main__box {
  width: 80vw;
  max-width: 1320px;
  margin: 0 auto;
  padding: 5rem 0 4rem 0;
}
.main__box h1 {
  text-align: center;
  font-family: "Poppins", sans-serif;
  font-weight: 600;
  font-size: 3.2rem;
  color: #1c3975;
  margin-bottom: 3rem;
}
.main__box hr {
  border-top: 3px solid #9b243a;
}
.button__wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
}
.manufacturers__button {
  background-color: #1c3975;
  padding: 1.4rem 2.4rem;
  font-family: "Poppins", sans-serif;
  font-size: 1.4rem;
  color: #fff;
  border-radius: 5px;
  text-decoration: none;
}

@media (max-width: 62em) {
  .singleItem__box {
    margin: 3rem 0;
    flex-direction: column;
  }
  .main__box h1 {
    font-size: 2.4rem;
  }
  .last__item {
    padding: 0 3rem 3rem 3rem;
  }
}
