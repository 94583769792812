.timeline-main-wrapper > div {
  padding: 0 !important;
}
.timeline-card-content h2 {
  font-family: "Roboto", sans-serif;
  font-weight: 300 !important;
  color: #666;
}
.timeline-card-content img {
  object-fit: contain !important;
}
.timeline-card-content div[mode="VERTICAL_ALTERNATING"] {
  margin: 0 auto;
}
.timeline-card-content div[mode="VERTICAL_ALTERNATING"] div {
  justify-content: flex-start !important;
}
