.section__wrapper {
  width: 80vw;
  margin: 0 auto;
  max-width: 1320px;
  display: flex;
  padding-top: 5rem;
}
.column__left {
  width: 50%;
  display: flex;
  flex-direction: column;
  margin-top: 5rem;
  padding-right: 5rem;
}
.column__right {
  width: 50%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;
}
.column__right img {
  max-width: 100%;
}
.column__left hr {
  width: 100%;
  border-top: 2px solid #9f2436;
  border-radius: 10px;
  margin: 2rem 0;
}
.column__left h1 {
  font-family: serif;
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  font-size: 3.2rem;
  color: #1c3975;
}
.left__firstparagraph {
  font-family: "Roboto", sans-serif;
  font-size: 1.8rem;
  font-weight: 500;
  text-transform: uppercase;
  color: #9b243a;
}
.left_lastparagraph {
  font-family: "Roboto", sans-serif;
  font-weight: 300;
  font-size: 1.8rem;
  margin: 1.5rem 0;
}

.accordion__container {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.column__right--section4 img {
  max-width: 600px;
  float: right;
}

.section__wrapper__reverse {
  width: 80vw;
  max-width: 1320px;
  margin: 0 auto;
  display: flex;
  padding-top: 5rem;
}
.column__left__reverse {
  width: 50%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.column__right__reverse {
  width: 50%;
  display: flex;
  padding-left: 5rem;
}
.accordion__container__reverse {
  width: 100%;
  margin-top: 5rem;
}

@media (max-width: 120em) {
  .column__right img {
    width: auto;
  }

  .column__right--section4 img {
    margin-right: 0;
    float: none;
    width: 100%;
  }
}

@media (max-width: 62em) {
  .section__wrapper {
    width: 80vw;
    flex-direction: column;
    padding: 0 0 5rem 0;
  }
  .column__left {
    width: 100%;
    display: flex;
    flex-direction: column;
    margin-top: 5rem;
    padding-right: 0;
    padding-bottom: 5rem;
  }
  .column__right {
    width: 100%;
    display: flex;
    flex-direction: column;
  }
  .column__right img {
    width: auto;
  }

  .section__wrapper__reverse {
    flex-direction: column-reverse;
  }
  .column__left__reverse {
    width: 100%;
  }
  .column__right__reverse {
    width: 100%;
    display: flex;
  }
  .accordion__container__reverse {
    width: 100%;
    margin-top: 0;
    margin-bottom: 5rem;
  }
  .column__right--section4 img {
    margin-right: 0;
    float: none;
    width: 100%;
  }
  .left__firstparagraph {
    font-size: 1.6rem;
  }
  .column__left h1 {
    font-size: 1.8rem;
  }
  .left_lastparagraph {
    font-size: 1.4rem;
    line-height: 2rem;
    font-weight: 300;
  }
  .column__right__reverse {
    padding-left: 0rem;
  }
}
