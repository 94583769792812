/* Modal.module.css */

/* Overlay to dim the background */
.overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 99999;
}

/* Modal box */
.modal {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    background: white;
    padding: 30px;
    margin: 30px;
    border-radius: 8px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    max-width:  500px;
    min-height: 200px;
    width: 100%;
    position: relative;
}

/* Close button */
.closeButton {
    background: #9f2436;
    color: white;
    border: none;
    padding: 10px 20px;
    border-radius: 4px;
    cursor: pointer;
    font-size: 14px;
    margin-top: 20px;
}

.closeButton:hover {
    background: #9f2430;
}

/* Open button */
.openButton {
    background: #007bff;
    color: white;
    border: none;
    padding: 10px 20px;
    border-radius: 4px;
    cursor: pointer;
    font-size: 14px;
}

.openButton:hover {
    background: #0056b3;
}

/* Container styles */
.container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
    background: #f5f5f5;
}

/* Modal title */
.modalTitle {
    font-size: 20px;
    font-weight: bold;
    margin-bottom: 10px;
}

/* Modal content */
.modalContent {
    font-size: 16px;
    color: #555;
}