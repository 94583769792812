.main__element {
  padding-top: 130px;
  margin: 0 auto;
  min-height: calc(100vh - 50px);
}
.main__overflow {
  overflow: hidden;
}
@media (max-width: 88em) {
  .main__element {
    padding-top: 130px;
  }
}
@media (max-width: 62em) {
  .main__element {
    padding-top: 0px;
    margin-left: 0;
  }
}
@media (max-height: 32em) {
  .main__element {
    padding-top: 75px;
    margin-left: 0;
  }
}

/* MAX HEIGHT */
@media (max-height: 32em) and (max-width: 88em) {
  .main__element {
    padding-top: 70px;
    margin-left: 0;
  }
}
@media (max-height: 32em) and (max-width: 62em) {
  .main__element {
    padding-top: 0px;
    margin-left: 0;
  }
}
