.section__background {
}
.main__section {
  width: 80vw;

  margin: 0 auto;
}
.main__wrapper {
  display: flex;
  max-width: 1320px;
  width: 80vw;
  margin: 4rem auto 0 auto;
}
.section__right {
  width: 50%;
  padding: 0 5%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.section__left {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 50%;
}
.section__left img {
  max-width: 100%;
}
.top__divider {
  background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTAwJSIgaGVpZ2h0PSIyNXB4IiB2aWV3Qm94PSIwIDAgMTI4MCAxNDAiIHByZXNlcnZlQXNwZWN0UmF0aW89Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PGcgZmlsbD0iI0ZGRkZGRiI+PHBhdGggZD0iTTY0MCAxNDBMMTI4MCAwSDB6IiBmaWxsLW9wYWNpdHk9Ii41Ii8+PHBhdGggZD0iTTY0MCA5OGw2NDAtOThIMHoiLz48L2c+PC9zdmc+);
  background-size: 100% 25px;
  top: 0;
  height: 25px;
  z-index: 1;
  transform: scale(1, 1);
}
.bottom__divider {
  background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTAwJSIgaGVpZ2h0PSIyNXB4IiB2aWV3Qm94PSIwIDAgMTI4MCAxNDAiIHByZXNlcnZlQXNwZWN0UmF0aW89Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PGcgZmlsbD0iI0ZGRkZGRiI+PHBhdGggZD0iTTY0MCAxMzlMMCAwdjE0MGgxMjgwVjBMNjQwIDEzOXoiIGZpbGwtb3BhY2l0eT0iLjUiLz48cGF0aCBkPSJNNjQwIDEzOUwwIDQydjk4aDEyODBWNDJsLTY0MCA5N3oiLz48L2c+PC9zdmc+);
  background-size: 100% 25px;
  bottom: 0;
  height: 25px;
  z-index: 1;
  transform: scale(1, 1);
}
.section__right h1 {
  color: #9b243a;
  padding-bottom: 2rem;
  font-size: 1.8rem;
  font-weight: 600;
  text-transform: uppercase;
  letter-spacing: 2px;
}
.section__right hr {
  border-top: 3px solid #9b243a;
  border-radius: 5px;
  margin-bottom: 2rem;
}
.section__right h2 {
  color: #1c3975;
  padding-bottom: 2rem;
  font-size: 3.4rem;
  font-weight: 500;
}
.section__right p {
  font-size: 1.8rem;
  line-height: 2.6rem;
  font-weight: 300;
}
@media (max-width: 88em) {
  .main__wrapper {
    width: 80vw;
  }
}

@media (max-width: 62em) {
  .section__left {
    display: none;
  }
  .section__right {
    width: 100%;
    padding: 2rem 0;
  }
  .main__wrapper {
    height: auto;
  }
  .section__right p {
    font-size: 1.4rem;
    line-height: 2rem;
    font-weight: 300;
  }
  .section__right h1 {
    font-size: 1.6rem;
  }
}
@media (max-width: 32em) {
  .main__wrapper {
    width: 80vw;
  }
}
