.map__reset {
  margin: 5rem 0;
  position: relative;
}
.active {
  fill: #9f2436;
}
.map__reset path:hover {
  fill: #9f2436;
}

.voivodship {
}
.tooltip {
  position: absolute;
  display: none;

  width: 400px;
  background-color: #fff;
  border: 2px solid #1c3975;
  padding: 2rem;
  transform: translate(-50%, -50%);
}
.position1 {
  display: block;
  top: 200px;
  left: 150px;
}
.position2 {
  display: block;
  top: 200px;
  left: 200px;
}
.position3 {
  display: block;
  top: 200px;
  left: 250px;
}
.position4 {
  display: block;
  top: 200px;
  left: 300px;
}
.position5 {
  display: block;
  top: 350px;
  left: 300px;
}
.position6 {
  display: block;
  top: 350px;
  left: 400px;
}
.position7 {
  display: block;
  top: 350px;
  left: 500px;
}
.position8 {
  display: block;
  top: 350px;
  left: 400px;
}
.position9 {
  display: block;
  top: 450px;
  left: 400px;
}
.position10 {
  display: block;
  top: 450px;
  left: 400px;
}
.position11 {
  display: block;
  top: 500px;
  left: 200px;
}
.position12 {
  display: block;
  top: 500px;
  left: 250px;
}
.position13 {
  display: block;
  top: 525px;
  left: 300px;
}
.position14 {
  display: block;
  top: 550px;
  left: 300px;
}
.position15 {
  display: block;
  top: 550px;
  left: 300px;
}
.position16 {
  display: block;
  top: 550px;
  left: 300px;
}
@media (max-width: 62em) {
  .position1 {
    display: block;
    top: 200px;
    left: 150px;
  }
  .position2 {
    display: block;
    top: 200px;
    left: 200px;
  }
  .position3 {
    display: block;
    top: 200px;
    left: 250px;
  }
  .position4 {
    display: block;
    top: 200px;
    left: 300px;
  }
  .position5 {
    display: block;
    top: 350px;
    left: 200px;
  }
  .position6 {
    display: block;
    top: 350px;
    left: 200px;
  }
  .position7 {
    display: block;
    top: 350px;
    left: 200px;
  }
  .position8 {
    display: block;
    top: 350px;
    left: 200px;
  }
  .position9 {
    display: block;
    top: 450px;
    left: 200px;
  }
  .position10 {
    display: block;
    top: 350px;
    left: 200px;
  }
  .position11 {
    display: block;
    top: 400px;
    left: 200px;
  }
  .position12 {
    display: block;
    top: 400px;
    left: 250px;
  }
  .position13 {
    display: block;
    top: 425px;
    left: 300px;
  }
  .position14 {
    display: block;
    top: 450px;
    left: 300px;
  }
  .position15 {
    display: block;
    top: 450px;
    left: 300px;
  }
  .position16 {
    display: block;
    top: 450px;
    left: 300px;
  }
  p {
    font-size: 1.4rem;
    line-height: 2rem;
    font-weight: 300;
  }
}
@media (max-width: 32em) {
  .position1,
  .position2,
  .position3,
  .position4,
  .position5,
  .position6,
  .position7,
  .position8,
  .position9,
  .position10,
  .position11,
  .position12,
  .position13,
  .position14,
  .position15,
  .position16 {
    display: none;
  }
}
