:root {
    --swiper-navigation-color: #58030f;
}

.sr__only {
    position: absolute;
    width: 100%;
    height: 100%;
    padding: 0;
    margin: -1px;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    white-space: nowrap;
    border-width: 0;
}

.clicable__button--s1 {
    position: absolute;
    /*top: 75%;*/
    /*left: 60%;*/
    /*right: 20%;*/
    /*bottom: 8%;*/
    inset: 0;
}

.clicable__button--s1_notmain {
    position: absolute;
    /*top: 75%;*/
    /*left: 60%;*/
    /*right: 20%;*/
    /*bottom: 8%;*/
    inset: 0;
}

.clicable__button--s1_whole {
    position: absolute;
    inset: 0;
}

.clicable__button--s1_mobile {
    position: absolute;
    top: 82%;
    left: 20%;
    right: 20%;
    bottom: 8%;
}

.clicable__button--s1_mobile_whole {
    position: absolute;
inset: 0;
}

.clicable__button--s1_mobile_notmain {
    position: absolute;
    /*top: 90%;*/
    /*left: 20%;*/
    /*right: 20%;*/
    /*bottom: 0%;*/
    inset: 0;
}

.bullets {
    background: #1c3975 !important;
    margin-bottom: 10px !important;
    opacity: 0.4 !important;
    width: 12px !important;
    height: 12px !important;
}

.bullets--active {
    background: #1c3975 !important;
    opacity: 1 !important;
}

.swiperInnerDiv {
    margin: 0 auto;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: calc(100% - 100px);
}

.swiperInnerDivMobile {
    display: none;
}

.carousel__image {
    object-fit: cover;
    max-width: 100%;
}

.carousel__container {
    position: relative;
    width: 100%;
    max-width: 1920px;
    margin: 0 auto;
    font-size: 2.2rem;
    font-family: "Roboto, sans-serif";
    color: rgb(30, 30, 30);
    font-weight: 300;
}

.wrapper__fix {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    background-color: rgba(255, 255, 255, 0.7);
    /* padding: 5rem 0; */

    padding: 0;
}

.wrapper__fix h1 {
    color: #1c3975;
    font-size: 3.2rem;
    padding: 0 15rem;
    font-family: "Poppins", sans-serif;
    font-weight: 600;
    text-align: center;
    max-width: 1440px;
}

.carousel__wrapper {
    position: relative;
    background-image: url("../assets/images/1.jpg");
    background-repeat: no-repeat;
    background-origin: border-box;
    background-size: cover;
    background-position: 0% 17%;
}

.carousel__firstitem {
    height: 400px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 0 15rem;
}

/* .carousel__container div p:first-child {
  margin-bottom: 1rem;
} */
/* .arrow__left {
  position: absolute;
  top: 50%;
  left: 0%;
  transform: translate(50%, -50%);
  height: 7rem;
  width: 7rem;
  color: #9f2436;
}

.arrow__right {
  position: absolute;
  top: 50%;
  right: 0%;
  transform: translate(-50%, -50%);
  height: 7rem;
  width: 7rem;
  color: #9f2436;
} */

.bottom__divider {
    position: absolute;
    width: 100%;
    background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTAwJSIgaGVpZ2h0PSIyNXB4IiB2aWV3Qm94PSIwIDAgMTI4MCAxNDAiIHByZXNlcnZlQXNwZWN0UmF0aW89Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PGcgZmlsbD0iI0Y2RjZGNyI+PHBhdGggZD0iTTY0MCAxMzlMMCAwdjE0MGgxMjgwVjBMNjQwIDEzOXoiIGZpbGwtb3BhY2l0eT0iLjUiLz48cGF0aCBkPSJNNjQwIDEzOUwwIDQydjk4aDEyODBWNDJsLTY0MCA5N3oiLz48L2c+PC9zdmc+);
    background-size: 100% 25px;
    bottom: 0;
    height: 25px;
    z-index: 1;
    transform: scale(1, 1);
    /* background-color: rgba(255, 255, 255, 0.7); */
}

@media (min-width: 121em) {
    .carousel__container {
        margin: 10rem auto;
    }
}

@media (max-width: 62em) {
    .mobile_hidden_temp {
        display: none;
    }

    .carousel__wrapper {
        background-image: none;
        background-color: transparent;
    }

    .wrapper__fix {
        padding: 20px;
        background-color: #f6f6f7;
    }

    .swiperInnerDiv {
        display: none;
    }

    .swiperInnerDivMobile {
        display: flex;
        justify-content: center;
        align-items: center;
        min-height: calc(100% - 100px);
    }

    .wrapper__fix h1 {
        font-size: 2rem;
        margin: 0 2rem;
        padding: 0 5rem;
        /* padding-top: 5rem; */
    }

    .carousel__firstitem {
        padding: 0 5rem;
    }

    .arrow__left {
        top: 50%;
        left: -5rem;
    }

    .arrow__right {
        top: 50%;
        right: -5rem;
    }

    .carousel__container div p {
        font-size: 1.6rem;
    }
}

@media (max-width: 32em) {
    .wrapper__fix h1 {
        padding: 0;
        font-size: 1.8rem;
    }
}
