.section__wrapper {
  padding-top: 5rem;
  width: 80vw;
  max-width: 1320px;
  margin: 0 auto;
  display: flex;
}
.column__left {
  width: 50%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-right: 5rem;
}
.column__left a {
  text-decoration: none;
  margin: 2rem 0;
}
.column__right {
  width: 50%;
  display: flex;
  justify-content: flex-end;
}

.column__right img {
  max-width: 100%;
}
.column__left a {
  background-color: #1c3975;
  color: #ffffff;
  border-radius: 5px;
  font-size: 2rem;
  font-family: "Roboto", sans-serif;
  font-weight: 300;
  padding: 1rem 2rem;
  width: 100px;
}
.column__left p {
  font-family: "Roboto", Helvetica, Arial, Lucida, sans-serif;
  font-weight: 300;
  font-size: 1.8rem;
}

@media (max-width: 62em) {
  .column__right {
    display: none;
  }
  .column__left {
    width: 100%;
    padding: 0;
  }
  .column__left p {
    font-size: 1.4rem;
    line-height: 2rem;
    font-weight: 300;
  }
  .column__left a {
    font-size: 1.8rem;
  }
}
