.singleItem__box {
  display: flex;
  align-items: center;

  flex: 1;
  background-color: #fff;
  justify-content: space-between;
  /* margin: 3rem; */
  border-radius: 20px;
  width: 50%;
}

.column__left {
  width: 70%;
  /* padding: 3rem; */
}
.column__left h2 {
  font-family: "Poppins", sans-serif;
  color: #1c3975;
  font-size: 3.2rem;
  margin-bottom: 2rem;
  font-weight: 600;
}
.column__left p {
  font-family: "Roboto", sans-serif;
  color: #666;
  font-size: 1.8rem;
  margin-bottom: 2rem;
  font-weight: 300;
}
.column__right {
  width: 30%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.column__right img {
  width: 100%;
  max-width: 500px;
}
.column__right a {
  margin: 0 3rem;
}

@media (max-width: 120em) {
  .singleItem__box {
    flex-direction: column;
    margin: 3rem 0;
    flex: 1;
  }
  .column__right img {
    width: auto;
    max-width: 250px;
  }
  .column__left {
    width: 100%;
  }
}

@media (max-width: 88em) {
  .singleItem__box {
    flex-direction: column;
    margin: 3rem 0;
    width: 100%;
  }
  .column__right img {
    width: auto;
    max-width: 200px;
  }
}
@media (max-width: 62em) {
  .column__left h2 {
    font-size: 1.8rem;
  }
  .column__left p {
    font-size: 1.6rem;
  }
  .singleItem__box {
    margin: 0;
  }
  .column__left p {
    font-size: 1.4rem;
    line-height: 2rem;
    font-weight: 300;
  }
}
