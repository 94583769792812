.container__main {
  display: flex;
  justify-content: center;
  width: 80vw;
  max-width: 1320px;
  margin: 0 auto;
}
.container__column {
  width: 50%;
  padding: 2rem 5rem 5rem 5rem;
}

.container__row {
  width: 100%;
  display: flex;
  flex-direction: row;
}
.row__texarea {
  width: 100%;
  height: 300px;
  padding: 12px 20px;
  margin-top: 20px;
  box-sizing: border-box;
  border: 2px solid #ccc;
  border-radius: 4px;
  background-color: #eee;
  font-size: 16px;
  resize: none;
  font-family: "Roboto", sans-serif;
  font-weight: 300;
}
.textarea__label {
  width: 100%;
  color: #737479;
}
.container__row--textarea {
  width: 100%;
  padding: 0 20px 20px 20px;
}
.checkout__bigtext {
  text-align: center;
  margin: 20px;
  width: 80vw;
  max-width: 1320px;
  margin: 2rem auto;
  font-family: "Poppins", sans-serif;
  font-weight: 600;
  font-size: 3.2rem;
  color: #1c3975;
}
.checkout__bigtext--h2 {
  margin-top: 2rem;
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-size: 2.6rem;
  color: #1c3975;
}
.checkout__bigtext--h3 {
  font-family: "Roboto", sans-serif;
  font-weight: 300;
  font-size: 1.8rem;
  color: #1c3975;
}
.captcha__checker input::-webkit-outer-spin-button,
.captcha__checker input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
.captcha__checker input[type="number"] {
  -moz-appearance: textfield;
}
.podsumowanie__divider {
  width: 80vw;
  max-width: 1320px;
  border-top: 3px solid #9f2436;
  border-radius: 10px;
  margin: 2rem auto;
}
.label__box input {
  color: #737479;
  margin: 0 1rem 0 0;
}
.label__box label {
  color: #737479;
}
.whatfor__bparagraph {
  margin: 2rem 0;
  color: #737479;
}
.captcha__styling {
  padding: 0 2rem;
  color: #737479;
}
.send__button {
  background-color: #1c3975;
  color: #ffffff;
  border-radius: 5px;
  font-size: 2rem;
  font-family: "Roboto", sans-serif;
  font-weight: 300;
  margin: 2rem 0;
  padding: 1rem 2rem;
}
.captcha__input {
  width: 3rem;
  height: 3rem;
}
.captcha__checker {
  margin: 1rem 0;
}
.message__output {
  margin-bottom: 10rem;
}
@media (max-width: 88em) {
  .container__column {
    width: 50%;
    padding: 1rem;
  }
  .container__main {
    display: flex;
    justify-content: space-between;
    gap: 2rem;
    width: 80vw;
    margin: 0 auto;
  }
}
@media (max-width: 75em) {
  .container__column {
    width: 50%;
    padding: 1rem;
  }
  .container__row {
    flex-direction: column;
  }
}
@media (max-width: 62em) {
  .container__column {
    width: 50%;
    padding: 1rem;
  }
}
@media (max-width: 50em) {
  .container__main {
    flex-direction: column;
  }
  .container__column {
    width: 90%;
    margin: 0 auto;
  }
  .container__row--textarea {
    padding: 0 00px 20px 0px;
  }
}
