.checkout__bigtext--h2 {
  margin-bottom: 2rem;
}
.checkout__bigtext--h2 {
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-size: 2.6rem;
  color: #1c3975;
}
.table__main {
  border: 1px solid #eee;
  border-collapse: collapse;
  color: #555;
}
table.table__main thead th {
  font-weight: normal;
}
table.table__main td,
table.table__main th {
  border: 1px solid #eee;
  padding: 10px 10px;
}
table.table__main th {
  font-size: 1.8rem;
  font-family: "Roboto", sans-serif;
  font-weight: 300;
  color: #737479;
}
