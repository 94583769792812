.main__wrapper {
  padding: 150px;
  min-height: calc(100vh - 50px);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.main__wrapper svg {
  width: 150px;
  height: 150px;
  color: #1c3975;
  margin: 5rem 0;
}
.columns__wrapper {
  display: flex;
  justify-content: center;
  gap: 10rem;
  margin: 5rem 0;
  text-align: center;
}
.columns__wrapper a {
  text-decoration: none;
  color: #1c3975;
  font-weight: 500;
  padding: 1rem;
}
.heading__main {
  font-size: 3rem;
  margin-bottom: 1rem;
}
