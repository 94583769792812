.main__wrapper {
  width: 80vw;
  max-width: 1320px;
  margin: 0 auto;
  padding: 5rem 0;
}

.map__wrapper {
  width: 600px;
  margin: 0 auto;
}
.main__heading {
  font-family: "Poppins", sans-serif;
  font-weight: 600;
  font-size: 3.2rem;
  color: #1c3975;
  margin: 1rem 0;
  text-align: center;
}
.contact__divider {
  border-top: 3px solid #9f2436;
  border-radius: 10px;
  margin: 2rem 0;
}
.flex__fix {
  display: flex;    align-items: stretch;

  justify-content: center;
  /*align-items: center;*/
  gap: 5rem;
  margin-bottom: 5rem;
}
.contact__box {
align-self: stretch;
  display: flex;
  /*justify-content: center;*/
  align-items: center;
  flex-direction: column;
  gap: 2rem;
  margin: 2rem 0;
}
.contact__box--tac {
  text-align: center;
}
.contact__box svg {
  width: 50px;
  height: 50px;
  color: #1c3975;
}
.heading__secondary {
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  font-size: 3.2rem;
  color: #1c3975;
  margin: 1rem 0;
}
@media (max-width: 62em) {
  .flex__fix {
    display: flex;
    flex-direction: column;
    gap: 2rem;
    margin-bottom: 2rem;
  }
  .map__wrapper {
    width: 90%;
    margin: 0 auto;
  }
  .main__wrapper {
    padding: 2rem 0;
  }
  .main__heading {
    font-size: 1.8rem;
  }
  .heading__secondary {
    font-size: 1.8rem;
  }
  .contact__box p {
    font-size: 1.4rem;
    line-height: 2rem;
    font-weight: 300;
  }
}
