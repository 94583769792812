.Footer {
  height: 50px;
  background-color: #232121;
  font-family: "Roboto", sans-serif;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
}
.footer__centered {
  text-align: center;
}
