.cartItem__container {
  display: flex;
  background: #fff;
  padding: 10px;
  transition: all 0.3s ease-out;
}
.cartItem__positioner:hover .cartItem__container {
  transform: translateX(-15px);
  transition: all 0.3s ease-out;
}
.cartItem__image {
  width: 80px;
  object-fit: contain;
}
.screen__reader--text {
  display: none;
}
.cartItem__productName {
  width: 100%;
  padding: 10px;
  display: flex;
  justify-content: center;
  /* align-items: left; */
  flex-direction: column;
  font-weight: 700;
  font-size: 1.6rem;
}
.cartItem__inputContainer {
  width: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.input__container {
  width: calc(100px - 40px);
  height: 30px;
}

.cartItem__input {
  width: 100%;
  height: 30px;
  line-height: 30px;
  min-height: 0;
  padding: 0;
  margin: 0;
  text-align: center;
  border: none;
  outline: none;
  color: #444;
  background-color: #eeeeee;
  box-shadow: none;
  font-size: 1.2rem;
  font-weight: 400;
}

.input__container input::-webkit-outer-spin-button,
.input__container input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Works for Firefox */
.input__container input[type="number"] {
  -moz-appearance: textfield;
}

.cartItem__amount {
  font-family: "Roboto";
  font-size: 2rem;
  font-weight: 400;
  color: #444;
  width: 30px;
  height: 30px;
  background-color: #eee;
  display: flex;
  justify-content: center;
  align-items: center;
}
.cartItem__positioner {
  position: relative;
  margin-bottom: 10px;
}
.cartItem__remove {
  z-index: -1;
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 50%;
  right: 5px;
  transform: translate(0, -50%);
  height: 100%;
  transition: all 0.3s ease-out;
}

.cartItem__positioner:hover .cartItem__remove {
  right: -15px;
  transition: all 0.3s ease-out;
  z-index: 1;
}

.cartItem__remove svg {
  width: 25px;
  height: 25px;
  color: white;
}
.cartItem__inputContainer > div {
  cursor: pointer;
}
