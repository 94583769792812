.singleItem__box {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #fff;
  margin: 3rem;
  -webkit-box-shadow: -5px 0px 19px 0px rgba(0, 0, 0, 0.11);
  box-shadow: -5px 0px 19px 0px rgba(0, 0, 0, 0.11);
  border-radius: 20px;
  padding: 2rem;
}
.column__left {
  width: 70%;
  padding: 3rem;
}
.column__left h2 {
  font-family: "Poppins", sans-serif;
  color: #1c3975;
  font-size: 3.2rem;
  margin-bottom: 2rem;
  font-weight: 600;
}
.column__left p {
  font-family: "Roboto", sans-serif;
  color: #666;
  font-size: 1.8rem;
  margin-bottom: 2rem;
  font-weight: 300;
}
.column__right {
  width: 30%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.column__right img {
  width: 100%;
  max-width: 500px;
}
.column__right a {
  margin: 0 3rem;
}

@media (max-width: 62em) {
  .singleItem__box {
    flex-direction: column;
  }
  .column__left {
    width: 100%;
  }
  .column__right img {
    width: 300px;
    max-width: 500px;
  }
}
@media (max-width: 32em) {
  .singleItem__box {
    flex-direction: column;
  }
  .column__left {
    width: 100%;
  }
  .column__right img {
    width: 150px;
    max-width: 200px;
  }
}
