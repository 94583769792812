.section__wrapper {
  max-width: 1320px;
  width: 80vw;
  margin: 0 auto;
  padding-top: 20px;
}
.section__wrapper .heading__main {
  text-align: center;
  font-family: "Poppins", sans-serif;
  font-weight: 600;
  font-size: 3.2rem;
  color: #1c3975;
}
.section__wrapper .heading__secondary {
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-size: 2.6rem;
  color: #1c3975;
  margin: 2rem 0;
}
.joinus__divider {
  border-top: 3px solid #9f2436;
  border-radius: 10px;
  margin-top: 2rem;
}
.unordered__list {
  list-style: none;
  font-family: "Roboto", sans-serif;
  font-weight: 300;
  font-size: 2rem;
}
.li__wrapper {
  display: flex;
  align-items: center;
  color: #5c5c5c;
  margin-bottom: 1rem;
}
.unordered__list .list__number {
  width: 4rem;
  height: 4rem;
  min-width: 4rem;
  background-color: #1c3975;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  margin-right: 1rem;
  font-size: 5rem;
}
.number__marker {
  width: 6px;
  height: 6px;
  background-color: white;
  border-radius: 9999px;
}
.section__wrapper .heading__tertiary {
  text-align: center;
  font-family: "Poppins", sans-serif;
  font-weight: 600;
  font-size: 3.2rem;
  color: #1c3975;
  margin: 2rem 0;
}
.email {
  font-family: "Roboto", sans-serif;
  font-weight: 400;
  font-size: 1.8rem;
  margin-bottom: 1rem;
  color: #1c3975;
}
.request {
  margin: 1rem 0;
  color: #5c5c5c;
  font-weight: 400;
}
.request__text {
  margin: 1rem 0 5rem 0;
  color: #5c5c5c;
}

@media (max-width: 40em) {
  .section__wrapper .heading__main {
    font-size: 1.8rem;
  }
  .li__wrapper {
    font-size: 1.4rem;
  }
  .section__wrapper .heading__secondary {
    font-size: 1.6rem;
  }
  .section__wrapper .heading__tertiary {
    font-size: 1.8rem;
  }
  .email {
    font-size: 1.4rem;
  }
  .request {
    font-size: 1.4rem;
    margin: 1rem 0;
    color: #5c5c5c;
    font-weight: 400;
  }
  .request__text {
    font-size: 1.4rem;
    margin: 1rem 0 5rem 0;
    color: #5c5c5c;
  }
}
