.main__wrapper {
  /* padding-top: 5rem; */
  padding-bottom: 5rem;
  background-size: contain;
  background-repeat: repeat;
  background-blend-mode: lighten;
  background-image: linear-gradient(
      180deg,
      #ffffff 0%,
      rgba(255, 255, 255, 0.74) 100%
    ),
    url("../components/assets/images/ofirmie_background.webp");
}
.main__width {
  width: 80vw;
  margin: 0 auto;
}
@media (max-width: 62em) {
  .main__wrapper {
    padding-top: 0;
  }
}
