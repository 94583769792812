.promotion__wrapper {
  position: relative;
  background-color: #f6f6f7;
  padding: 0 20px;
  padding-top: 25px;
}
.promo__button {
  padding: 2rem;
  background-color: #f6f6f7;
  border-radius: 10px;
  color: #131c6e;
  font-family: "Roboto", Helvetica, Arial, Lucida, sans-serif;
  font-weight: 500;
  font-size: 2rem;
  margin-left: 2rem;
  margin-right: 2rem;
  margin-bottom: 1rem;
  text-decoration: none;
  margin-bottom: 2rem;
}
.promo__code {
  padding: 2rem;
  background-color: #f6f6f7;
  border-radius: 10px;
  color: #131c6e;
  font-family: "Roboto", Helvetica, Arial, Lucida, sans-serif;
  font-weight: 500;
  font-size: 2rem;
  margin-left: 2rem;
  margin-right: 2rem;
  margin-bottom: 1rem;
  text-decoration: none;
  margin-bottom: 2rem;
}
.top__divider {
  position: absolute;
  background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTAwJSIgaGVpZ2h0PSIyNXB4IiB2aWV3Qm94PSIwIDAgMTI4MCAxNDAiIHByZXNlcnZlQXNwZWN0UmF0aW89Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PGcgZmlsbD0iI0Y2RjZGNyI+PHBhdGggZD0iTTY0MCAxNDBMMTI4MCAwSDB6IiBmaWxsLW9wYWNpdHk9Ii41Ii8+PHBhdGggZD0iTTY0MCA5OGw2NDAtOThIMHoiLz48L2c+PC9zdmc+);
  background-size: 100% 25px;
  top: 0;
  height: 25px;
  width: 100%;
  z-index: 10;
  transform: scale(1, 1);
}

.flex__wrapper {
  display: flex;
}
.promotion__left {
  padding: 10rem 0;
  width: 50%;
  background-image: linear-gradient(0deg, #821e2d 15%, #9e2335 100%);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.promotion__right {
  padding: 10rem 0;
  width: 100%;
  background-image: linear-gradient(0deg, rgb(17, 25, 95) 15%, #1c3975 100%);
  margin-left: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.image__container {
  max-width: clamp(150px, 50%, 350px);
  background-color: #f6f6f7;
  border-radius: 50%;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 60px 0;
}
.starlab__Logo {
  width: 50%;
}
.bottom__divider {
  position: absolute;
  background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTAwJSIgaGVpZ2h0PSIyNXB4IiB2aWV3Qm94PSIwIDAgMTI4MCAxNDAiIHByZXNlcnZlQXNwZWN0UmF0aW89Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PGcgZmlsbD0iI0Y2RjZGNyI+PHBhdGggZD0iTTY0MCAxMzlMMCAwdjE0MGgxMjgwVjBMNjQwIDEzOXoiIGZpbGwtb3BhY2l0eT0iLjUiLz48cGF0aCBkPSJNNjQwIDEzOUwwIDQydjk4aDEyODBWNDJsLTY0MCA5N3oiLz48L2c+PC9zdmc+);
  background-size: 100% 25px;
  bottom: 0;
  height: 25px;
  width: calc(100% - 40px);
  z-index: 10;
  transform: scale(1, 1);
}
.promotion__left img {
  max-width: 70%;
  height: auto;
}

.percentage__value {
  max-width: 70%;
  text-align: center;
  margin: 3rem 0;
  font-family: "Poppins", Helvetica, Arial, Lucida, sans-serif;
  font-weight: 600;
  font-size: 3rem;
  color: #fff;
  line-height: 4rem;
}
.percentage__value2 {
  max-width: 70%;
  text-align: center;
  margin: 0 0 3rem 0;
  font-family: "Poppins", Helvetica, Arial, Lucida, sans-serif;
  font-weight: 600;
  font-size: 3rem;
  color: #fff;
  line-height: 4rem;
}
.promotion__timer {
  margin-top: 2rem;
  display: flex;
  gap: 2rem;
  text-align: center;
  justify-content: center;
  align-items: center;
  opacity: 0.4;
  font-size: 4rem;
  font-family: Roboto, sans-serif;
  color: #fff;
  font-weight: 500;
}
.promotion__title {
  font-family: "Roboto", Helvetica, Arial, Lucida, sans-serif;
  font-weight: 300;
  font-size: 20px;
  color: #ffffff !important;
  margin-bottom: 1rem;
}
.timer__desc {
  font-size: 1.2rem;
}
.timer__dots {
  margin-top: -2rem;
}

@media (max-width: 62em) {
  .flex__wrapper {
    flex-direction: column;
    margin-left: 0px;
  }
  .promotion__left {
    width: 100%;
    margin-bottom: 20px;
  }
  .promotion__right {
    width: 100%;
    margin-left: 0px;
  }
  .image__container {
  }
  .starlab__Logo {
  }
  .percentage__value {
    font-size: 3rem;
  }
  .promotion__title {
    font-size: 1.6rem;
  }
  .promotion__timer {
    font-size: 3rem;
  }
}
@media (max-width: 32em) {
  .promotion__wrapper {
    margin: 0;
  }
}
