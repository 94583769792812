.cart__miniature {
  position: fixed;
  left: 20px;
  bottom: 20px;
  width: 80px;
  height: 80px;
  z-index: 99998;
  background-color: white;
  color: #9f2436;
  border-radius: 3px;
  box-shadow: rgba(0, 0, 0, 0.15) 0px 0px 20px;
  transition: bottom 0.2s ease-in;
}
.cart__miniature:hover {
  bottom: 25px;
  transition: bottom 0.2s ease-in;
}
.cart__flex {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  font-size: 1.6rem;
  font-family: "Roboto", sans-serif;
  font-weight: 700;
  text-align: center;
  line-height: 1.8rem;
  color: #000;
}
.cart__flex > span {
  margin-top: 5px;
}
.cart__image {
  width: 25px;
  height: 25px;
}
.cart__number {
  display: flex;
  justify-content: center;
  align-items: center;
}
.cart__numberbox {
  position: absolute;
  top: -12px;
  right: -12px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #9f2436;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  color: white;
  font-family: "Roboto", sans-serif;
  font-size: 1.2rem;
  line-height: 30px;
  text-align: center;
  font-weight: 500;
}
.cart__sidebar {
  position: fixed;
  z-index: 99999;
  top: 0;
  right: 0;
  width: 450px;
  height: 100vh;
  height: 100dvh;
  background-color: #9f2436;
  transition: right 0.3s ease-out;
}
.sidebar__top {
  width: 100%;
  height: 50px;
  padding: 20px;
  background-color: #7c1d2e;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 1.4rem;
  font-family: "Roboto", sans-serif;
  color: white;
  font-weight: 700;
}
.sidebar__close {
  color: white;
  width: 20px;
  height: 20px;
}
.sidebar__close svg.sidebar__close--fill {
  height: 100%;
  width: 100%;
}
.cartitems__container {
  padding: 20px;
  height: calc(100vh - 180px);
  height: calc(100dvh - 180px);
  display: flex;
  flex-direction: column;
  overflow-y: scroll;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

.cartitems__container::-webkit-scrollbar {
  display: none;
}

.sidebar__footer {
  height: 130px;
  padding: 20px;
  font-family: "Roboto", sans-serif;
  font-size: 1.4rem;
  text-align: center;
}

.sidebar__button a {
  height: 40px;
  line-height: 36px;
  text-align: center;
  padding: 0 10px;
  background-color: #ffffff;
  color: #9f2436;
  font-weight: 700;
  border: 2px solid #ffffff;
  text-decoration: none;
  display: inline-block;
  width: 100%;
  text-transform: uppercase;
  border-radius: 0;
  -moz-border-radius: 0;
  -webkit-border-radius: 0;
  -webkit-transition: all 0.5s;
  -moz-transition: all 0.5s;
  -ms-transition: all 0.5s;
  -o-transition: all 0.5s;
  transition: all 0.5s;
}

.sidebar__button a:hover {
  background-color: #9f2436;
  color: #fff;
}

.sidebar__span span {
  margin-top: 15px;
  line-height: 20px;
  text-align: center;
  padding: 2px 0;
  color: #fff;
  font-weight: 700;
  display: inline-block;
  text-transform: uppercase;
  border-radius: 0;
  border-bottom: 1px solid #fff;
}

.backdrop {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 99998;
  background: rgba(0, 0, 0, 0.7);
  width: 100%;
  height: 100vh;
  height: 100dvh;
}

.sidebar__top svg {
  width: 25px;
  height: 25px;
  color: #fff;
  transition: transform 0.3s ease-out;
}
.sidebar__top svg:hover {
  transform: scale(1.3);
  transition: transform 0.3s ease-out;
}

.sidebar-invisible {
  right: -450px !important;
  transition: right 0.3s ease-out;
}

@media (max-width: 80em) {
}
@media (max-width: 62em) {
  .cart__sidebar {
    width: 100vw;
  }
  .sidebar-invisible {
    right: -100vw !important;
  }
}
