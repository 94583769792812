.button__wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
}
.manufacturers__button {
  background-color: #1c3975;
  padding: 1.4rem 2.4rem;
  font-family: "Poppins", sans-serif;
  font-size: 1.4rem;
  color: #fff;
  border-radius: 5px;
  text-decoration: none;
  margin: 0 0 2rem 0;
}
.main__wrapper {
  /* padding-top: 5rem; */
  padding-bottom: 5rem;
  background-size: contain;
  background-repeat: repeat;
  background-blend-mode: lighten;
}
.main__width {
  width: 80vw;
  max-width: 1320px;
  margin: 0 auto;
}
.h1__decorated {
  font-family: "Poppins", sans-serif;
  font-weight: 600;
  font-size: 3.2rem;
  color: #1c3975;
  text-align: center;
  margin: 5rem 0 2rem 0;
}
.first__decoration {
  background-color: #f8f8f8;
  /* background-image: linear-gradient(90deg, #ffffff 50%, rgba(255, 255, 255, 0) 50%),
    url(../components/assets/images/four-team-members-expressing-togetherness-scaled.webp);
  background-size: cover; */
  height: 300px;
  background-position: center;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.inside__divider {
  position: absolute;
  background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTAwJSIgaGVpZ2h0PSI1MHZoIiB2aWV3Qm94PSIwIDAgMTI4MCAxNDAiIHByZXNlcnZlQXNwZWN0UmF0aW89Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PGcgZmlsbD0icmdiYSgyMzgsMjM4LDIzOCwwLjcpIj48cGF0aCBkPSJNNjQwIDE0MEwxMjgwIDBIMHoiLz48L2c+PC9zdmc+);
  background-size: 100% 100%;
  top: 0;
  height: 100%;
  width: 100%;
  transform: scale(1, 1);
}
.first__decoration p,
.first__decoration h1 {
  z-index: 1;
}
.first__decoration h1 {
  font-family: "Poppins", sans-serif;
  font-weight: 600;
  font-size: 3.2rem;
  color: #1c3975;
}
.first__decoration p {
  font-family: "Roboto", sans-serif;
  font-size: 1.8rem;
  font-weight: 500;
  text-transform: uppercase;
  color: #9f2436;
}
.first__decoration h2 {
  font-family: "Poppins", sans-serif;
  font-weight: 600;
  font-size: 3.2rem;
  color: #1c3975;
  text-align: center;
  z-index: 1;
  margin: 1rem 2.5rem;
}
@media (max-width: 62em) {
  .main__wrapper {
    padding-top: 0;
  }
}
