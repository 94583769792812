.search__item {
  padding: 10px;
  color: #000;
  font-size: 1.6rem;
  font-family: "Poppins", sans-serif;
  border-bottom: 1px solid rgba(0, 0, 0, 0.15);
}
.search__item:last-child {
  border-bottom: none;
}

div[data-type="search__res"] .result__svg {
  position: relative;
  left: 0px;
  max-height: 20px;

  max-width: 20px;
  opacity: 0.5;
}

div[data-type="search__res"] p {
  display: flex;
  justify-content: space-between;
}
