label > a {
    display: inline;
}

.checkout__bigtext--h3 {
    font-family: "Roboto", sans-serif;
    font-weight: 300;
    font-size: 1.8rem;
    color: #1c3975;
}

.rodo__form {
    margin: 6rem 0 0 0;
}

.rodo__form form {
    margin: 0 auto;
    width: 75vw;
    max-width: 1320px;
}

.rodo__heading {
    font-size: 3.2rem;

    font-family: "Poppins", sans-serif;
    font-weight: 600;
    color: #1c3975;
    text-align: center;
}

.container__row--half {
    width: 50% !important;
}

.container__main {
    display: flex;
    justify-content: center;
    width: 80vw;
    margin: 0 auto;
}

.container__column {
    width: 50%;
    padding: 2rem 5rem 5rem 5rem;
}

.singleInput__column {
}

.container__row {
    width: 100%;
    display: flex;
    flex-direction: row;
    gap: 1rem;
}

.row__texarea {
    width: 100%;
    height: 300px;
    padding: 12px 20px;
    margin-top: 20px;
    box-sizing: border-box;
    border: 2px solid #ccc;
    border-radius: 4px;
    background-color: #eee;
    font-size: 16px;
    resize: none;
    font-family: "Roboto", sans-serif;
    font-weight: 300;
}

.textarea__label {
    width: 100%;
    color: #737479;
}

.container__row--textarea {
    width: 100%;
    padding: 2rem 1rem;
}

.checkout__bigtext {
    margin: 20px;
    width: 80vw;
    margin: 2rem auto;
    font-family: "Poppins", sans-serif;
    font-weight: 600;
    font-size: 3.2rem;
    color: #1c3975;
}

.checkout__bigtext--h2 {
    margin-left: 2rem;
    margin-top: 2rem;
    font-family: "Poppins", sans-serif;
    font-weight: 400;
    font-size: 2.6rem;
    color: #1c3975;
}

.captcha__checker input::-webkit-outer-spin-button,
.captcha__checker input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

/* Firefox */
.captcha__checker input[type="number"] {
    -moz-appearance: textfield;
}

.podsumowanie__divider {
    width: 80vw;
    border-top: 3px solid #9f2436;
    border-radius: 10px;
    margin: 2rem auto;
}

.label__box input {
    color: #737479;
    margin: 0 1rem 0 0;
}

.label__box label {
    color: #737479;
}

.whatfor__bparagraph {
    margin: 2rem 0;
    color: #737479;
}

.captcha__styling {
    padding: 0 2rem;
    color: #737479;
}

.send__button {
    background-color: #1c3975;
    color: #ffffff;
    border-radius: 5px;
    font-size: 2rem;
    font-family: "Roboto", sans-serif;
    font-weight: 300;
    margin: 2rem 0;
    padding: 1rem 2rem;
}

.captcha__input {
    width: 3rem;
    height: 3rem;
}

.captcha__checker {
    margin: 1rem 0;
}

.message__output {
    margin-bottom: 5rem;
}

.label__box {
    margin: 10px;
}

.information__heading {
    text-align: center;
    margin-bottom: 4rem;
}
.information__paragraph {
    margin-bottom: 4rem;
}

.container__checkboxwrapper {
    display: flex;
    align-items: center;
    gap: 1rem;
    margin: 2rem;
}

.container__agreementswrapper {
    margin: 0.5rem;
}

@media (max-width: 88em) {
    .container__column {
        width: 50%;
        padding: 1rem;
    }

    .container__main {
        display: flex;
        justify-content: space-between;
        gap: 2rem;
        width: 80vw;
        margin: 0 auto;
    }

    .container__row--textarea {
        width: 100%;
        padding: 2rem 0rem;
    }
}

@media (max-width: 75em) {
    .container__column {
        width: 50%;
        padding: 1rem;
    }

    .container__row {
        flex-direction: column;
    }

    .container__row--half {
        width: 100% !important;
    }
}

@media (max-width: 62em) {
    .container__column {
        width: 50%;
        padding: 1rem;
    }

    .rodo__heading {
        font-size: 2rem;
    }
}

@media (max-width: 50em) {
    .rodo__form form {
        width: 80vw;
    }

    .container__main {
        flex-direction: column;
    }

    .container__column {
        width: 90%;
        margin: 0 auto;
    }

    .container__row--textarea {
        width: 100%;
        padding: 2rem 0 2rem 0;
    }

    .rodo__heading {
        font-size: 1.8rem;
    }
    .container__checkboxwrapper {
  flex-direction: column;
    align-items: flex-start;
    }

}

@media (max-width: 32em) {
    .label__box {
        font-size: 1.4rem;
    }

    .captcha__styling {
        font-size: 1.4rem;
        padding: 0 1rem;
    }

    .captcha__checker {
        font-size: 1.4rem;
    }

    .send__button {
        font-size: 1.8rem;
    }

    .message__output {
        margin-bottom: 2rem;
    }
}
