.search__container {
  display: none;
  box-sizing: border-box;
  position: absolute;
  background-color: #f5f5f5;
  width: 635px;
  padding: 10px 10px;
  color: red;
  top: 110px;
}

.search__container--active {
  display: block;
  box-sizing: border-box;
  position: absolute;
  background-color: #f5f5f5;
  width: 635px;
  padding: 10px 10px;
  color: red;
  top: 110px;
}

@media (max-width: 62em) {
  .search__container--active {
    width: 80vw;
    top: 170px;
    z-index: 9991;
  }
}
@media (max-height: 32em) {
  .search__container--active {
    top: 70px;
    max-height: 60vh;
    overflow: auto;
  }
}
@media (max-height: 32em) and (max-width: 62em) {
  .search__container--active {
    top: 115px;
    max-height: 60vh;
    overflow: auto;
  }
}
