.section__wrapper {
  width: 80%;
  max-width: 1320px;
  margin: 0 auto;
  padding: 5rem 0;
}
.section__wrapper h1 {
  font-family: "Poppins", sans-serif;
  font-size: 3.2rem;
  color: #1c3975;
  font-weight: 500;
}
.section__wrapper hr {
  border-top: 3px solid #9f2436;
  border-radius: 10px;
}
.logos__container {
  display: flex;
  flex-wrap: wrap;
  gap: 2rem;
  justify-content: space-around;
}
.logos__wrapper {
  padding-top: 5rem;
}
.logos__divider {
  margin-bottom: 5rem;
}
