.spinner {
    position: absolute;
    right: 0;
}


.dropdown_flash {
    display: none !important;
}

.main__header {
    overflow: visible;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    background-color: #fff;
    z-index: 9999;
    box-shadow: 0px 8px 18px -6px rgba(0, 0, 0, 0.3);
    display: flex;
    align-items: stretch;
}

.dropdown__menuMobile {
    display: none;
}

.header__wrapper {
    margin: 0px 10vw;
    padding: 10px 0;
    width: 80vw;
    display: flex;
}

.header__image {
    width: 250px;
    height: 110px;
}

.header__wrapper img {
    width: 250px;
    height: 110px;
    object-fit: contain;
}

.header__navigation {
    width: calc(100% - 250px);
    display: flex;
    justify-content: flex-end;
    gap: 2rem;
}

.navigation__wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
}

.Navbar ul {
    font-size: 1.6rem;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    gap: 2rem;
}

.Navbar li {
    list-style: none;
}

.Navbar li a {
    position: relative;
    text-decoration: none;
    color: #000;
    font-size: 1.6rem;
    font-family: "Poppins", sans-serif;
}

.Navbar li a:hover {
    color: #9f2436;
}

.Navbar li a:hover::before {
    right: 0;
}

.Navbar li a:before {
    content: "";
    position: absolute;
    left: 0;
    right: 100%;
    bottom: -10%;
    background: #9f2436;
    height: 3px;
    -webkit-transition-property: right;
    transition-property: right;
    -webkit-transition-duration: 0.3s;
    transition-duration: 0.3s;
    -webkit-transition-timing-function: ease-out;
    transition-timing-function: ease-out;
}

.Navbar li a.active {
    color: #9f2436;
}

.Navbar li a.active::before {
    content: "";
    position: absolute;
    left: 0;
    right: 0;
    bottom: -10%;
    background: #9f2436;
    height: 3px;
    -webkit-transition-property: right;
    transition-property: right;
    -webkit-transition-duration: 0.3s;
    transition-duration: 0.3s;
    -webkit-transition-timing-function: ease-out;
    transition-timing-function: ease-out;
}

.navigation__search {
    display: flex;
    justify-content: flex-end;
    margin-top: 20px;
}

.navigation__search span {
    position: relative;
}

.navigation__search span svg {
    left: 0;
    top: 3px;
}

.navigation__input--container {
    position: relative;
    display: flex;
    align-items: center;
}

.navigation__search input {
    box-sizing: border-box;
    width: 635px;
    height: 40px;
    border: 1px solid #9b243a;
    border-radius: 3px;
    padding-left: 35px;
}

.navigation__search input:focus {
    background-color: #f5f5f5;

    outline: none;
}

.navigation__search input::placeholder {
    color: rgba(0, 0, 0, 0.5);
    font-size: 1.4rem;
    letter-spacing: 0.5px;
    font-family: "Poppins", sans-serif;
}

.navigation__search svg {
    position: absolute;
    left: 10px;
    max-height: 20px;
    width: 100%;
    max-width: 20px;
    opacity: 0.5;
}

.navigation__search .navigation__input--container > div > svg {
    opacity: 1;
}

.dropdown__menu .dropdown__button {
    border: none;
    outline: none;
    padding: 14px 16px;
    background-color: inherit;
    font: inherit;
    margin: 0;
    color: #000;
    font-size: 1.6rem;
    font-family: "Poppins", sans-serif;
}

.dropdown__button {
    position: relative;
}

.dropdown__button:hover .button__paragraph:before {
    right: 0;
}

.dropdown__button .button__paragraph:before {
    content: "";
    position: absolute;
    left: 0;
    right: 100%;
    bottom: 20%;
    background: #9f2436;
    height: 3px;
    -webkit-transition-property: right;
    transition-property: right;
    -webkit-transition-duration: 0.3s;
    transition-duration: 0.3s;
    -webkit-transition-timing-function: ease-out;
    transition-timing-function: ease-out;
}

.dropdown__menu .dropdown__content {
    display: none;
}

.dropdown__menu:hover .dropdown__content {
    display: block;
    position: absolute;
    left: 10vw;
    right: 10vw;
    width: 80vw;
    background-color: #fff;
}

.dropdown__row {
    display: flex;
    justify-content: space-around;
}

.dropdown__separator {
    border-bottom: 1px solid rgba(0, 0, 0, 0.15);
    width: 95%;
    margin: 0 auto;
}

.dropdown__column {
    width: 25%;
    text-align: center;
    margin: 15px 0;
}

.dropdown__column a {
    text-decoration: none;
    color: #000;
    font-size: 1.6rem;
    font-family: "Poppins", sans-serif;
    position: relative;
}

.dropdown__column a.active {
    color: #9f2436;
}

.dropdown__column a:hover {
    color: #9f2436;
}

.dropdown__column a:before {
    content: "";
    position: absolute;
    left: 0;
    right: 100%;
    bottom: -10%;
    background: #9f2436;
    height: 3px;
    -webkit-transition-property: right;
    transition-property: right;
    -webkit-transition-duration: 0.3s;
    transition-duration: 0.3s;
    -webkit-transition-timing-function: ease-out;
    transition-timing-function: ease-out;
}

.dropdown__column a:hover::before {
    right: 0;
}

.dropdown__column a.active::before {
    content: "";
    position: absolute;
    left: 0;
    right: 0;
    bottom: -10%;
    background: #9f2436;
    height: 3px;
    -webkit-transition-property: right;
    transition-property: right;
    -webkit-transition-duration: 0.3s;
    transition-duration: 0.3s;
    -webkit-transition-timing-function: ease-out;
    transition-timing-function: ease-out;
}

.hamburger__menu {
    display: none;
}

.hamburger__bar {
    display: block;
    width: 45px;
    height: 5px;
    margin: 12px auto;
    border-radius: 5px;
    -webkit-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
    background-color: #1c3975;
}

.hamburger__bar:first-child {
    margin-top: 0px;
}

.hamburger__bar:last-child {
    margin-bottom: 0px;
}

.button__paragraph svg {
    position: relative;
    left: 30px;
}

@media (max-width: 88em) {
    .dropdown__menu .dropdown__button {
        padding: 14px 10px;
    }

    .navigation__search input {
        width: 500px;
    }

    .Navbar ul {
        gap: 1rem;
    }

    .Navbar li a {
        font-size: 1.4rem;
    }

    .header__wrapper img {
        width: 150px;
    }

    .header__image {
        width: 150px;
    }

    .header__navigation {
        width: calc(100% - 150px);
    }
}

@media (max-width: 62em) {
    .main__header {
        position: static;
    }

    .hamburger__menu {
        display: block;
        height: 110px;
        display: flex;
        justify-content: flex-end;
        align-items: center;
    }

    .Navbar ul {
        position: absolute;
        left: -200%;
    }

    .navigation__search {
        margin-left: -150px;
    }

    .navigation__input--container {
        width: 100%;
    }

    .navigation__input--container input {
        width: 100%;
    }

    .dropdown__column {
        width: 100%;
    }

    .dropdown__row {
        flex-wrap: wrap;
    }

    .dropdown__menu:hover .dropdown__content {
        max-height: 60vh;
        overflow-y: scroll;
    }

    .Navbar ul.mobile__open {
        left: auto;
        font-size: 5rem;
        top: 120px;
        right: calc(10% - 15px);
        width: calc(80% + 15px);
        flex-direction: column;
        background-color: #fff;
        z-index: 999999099999;
        max-height: 60vh;
        justify-content: flex-start;
        overflow-y: auto;
        border: 1px solid #eee;
    }

    .Navbar ul.mobile__open li {
        padding: 10px;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .Navbar ul.mobile__open a {
        font-size: 1.6rem;
    }

    .navigation__search {
        margin-top: -25px;
        margin-bottom: 0px;
    }

    .dropdown__content {
        z-index: 999;
    }
}

@media (max-height: 32em) {
    .header__wrapper {
        padding: 0;
    }

    .header__wrapper img {
        height: 50px;
    }

    .header__image {
        height: 50px;
    }

    .hamburger__menu {
        height: 50px;
    }

    .dropdown__menu .dropdown__button {
        padding: 0 0 14px 0;
        font-size: 1.2rem;
    }

    .search__container--active {
        top: 70px;
    }

    .Navbar ul.mobile__open {
        top: 60px;
    }

    .navigation__search {
        margin-top: 5px;
        margin-bottom: 5px;
    }

    .dropdown__content {
        max-height: 70vh;
        overflow-y: scroll;
    }
}
