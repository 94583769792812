.slider__wrapper {
  width: 80vw;
  max-width: 1320px;
  margin: 0 auto;
  padding: 0 0 12rem 0;
}
.slider__wrapper h3 {
  text-align: center;
}
.carousel__image {
  width: 100%;
  padding: 10px;
}

:global(.slick-slide) > div {
  height: 253px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

@media (max-width: 32em) {
  .slider__wrapper {
    width: 80vw;
    margin: 0 auto;
    padding: 3rem 0;
  }
}
