.nores {
  box-sizing: border-box;
  position: absolute;
  background-color: #f5f5f5;
  width: 635px;
  padding: 10px 10px;
  color: #9f2436;
  top: 110px;
  font-size: 1.6rem;
  font-family: "Poppins", sans-serif;
  text-align: center;
}
@media screen and (max-width: 62em) {
  .nores {
    width: auto;
    top: 155px;
  }
}
