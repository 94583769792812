.clicked__informations {
  width: 600px;
  height: 100px;
  padding: 2rem;
  margin: 0 0 5rem 0;
  border: 0px solid black;
}
@media (max-width: 32em) {
  .clicked__informations {
    width: 100%;
    padding: 0;
  }
}
